<template>
  <div :style="'height:'+ height" style="position: sticky;">

    <LMap
      :zoom="zoom"
      :center="center"
    >
      <LTileLayer :url="url" />
      <div
        v-for="(val, index) in latlng"
        :key="index"
      >
        <LMarker :lat-lng="[val.latitude,val.longitude]">
          <l-popup>{{ val.name }}</l-popup>
        </LMarker>
      </div>
    </LMap>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet"
export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  props:{
    draggable:{
      type:Boolean,
      default:true
    },
    height:{
      type:String,
      default:'50vh'
    },
    zoom:{
      type:Number,
      default:16
    },
    dataurl:{
      type:String,
      default:''
    }

  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      bounds: null,
      latlng:[],
      center:[-7.24920,112.75100]
    }
  },
  mounted() {
    this.$http.get(this.dataurl).then(res=>{
      this.latlng = res.data.data
    })
  },
  methods: {
    addMarker(event) {
      // alert(JSON.stringify(event.latlng))
      // console.log(event.latlng)
      // this.marker = event.latlng
      // this.marker =event.latlng
      this.$emit('updateMap', event.latlng)
    },
    dragMarker(latlng){
      this.$emit('updateMap', latlng)
    },
    clickMarker(latlng){
      this.zoom = 11
      this.center = [latlng.latitude, latlng.longitude]
    }
  }
}
</script>